<template>
	<v-col cols="12">
		<editor :toref="component" :inputref="`${component}Input`"
			:toplaceholder='toplaceholder' v-model="triz.backgroud.calculate" />
	</v-col>
</template>

<script>
	import editor from './editor/editor.vue'

	export default {
		components: {
			editor,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
			active: {
				type: Array
			},
			component: {
				type: String
			},
			toplaceholder: {
				type: String
			}
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
			}
		},
		mounted() {},
		methods: {
			change() {
				this.$emit('change', this.triz)
			},
		},
	}
</script>