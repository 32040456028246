<template>
  <div class="font-size-24">
    <v-row align="center" justify="center">
      <img id="s-curve" src="/scurve.svg" />
    </v-row>
    <v-row align="center" justify="center">
      <div align="center" justify="center">图 系统的进化曲线</div>
    </v-row>
    <v-row align="center" justify="center">
      <p>
        每个技术系统的进化都按照生物进化的模式进行的，一般都要经历以下四个阶段：
        婴儿期、成长期、成熟期、衰退期。每个阶段都会呈现出不同的特点，当前传统的
        <triz-validator rules="required" id="S曲线进化法则-旧系统名称">
          <InnerTextField @input="change" v-model="triz.curve.old" />
        </triz-validator>
        已经处于
         <triz-validator rules="required" id="S曲线进化法则-周期">
          <triz-select :table="false" v-model="triz.curve.piriod" flat :items="piriod" solo >
          </triz-select>
        </triz-validator>
        ，系统的性能很难再有所增加，而时间性能曲线表明，随着时间的延续成熟期的产品性能很难有所增加，
        而新的产品即将会进入婴儿期出现进入市场。由此可见，目前
        {{ triz.curve.old }}
        系统成熟发展预示着一个新的产品的出现，而我们利用TRIZ理论方法的设计作品——
        <triz-validator rules="required" id="S曲线进化法则-新系统名称">
          <InnerTextField @input="change" v-model="triz.curve.new" />
        </triz-validator>
        ，正是处于一个新型
         {{ triz.curve.old }}
        设备的婴儿期，从产品发展的S曲线簇也可以看出我们的产品未来将会有很大的市场发展空间。
      </p>
    </v-row>
  </div>
</template>

<script>
import InnerTextField from '@/components/InnerTextField'
import { piriod } from '../../../store/default'

export default {
  components: {
    InnerTextField: InnerTextField,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      piriod
      // triz: this.triz,
    }
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
<style scoped lang="scss">
.nineDiv {
  .nineContent {
    float: left;
    border: 2px solid #333;
    .contentTitle {
      font-size: 18px;
      font-weight: bold;
      color: #e73535e5;
      width: 100%;
      height: 20px;
      text-align: center;
    }
    .textarea {
      font-size: 12px;
      width: 100%;
      margin-top: 5px;
      height: calc(100% - 24px);
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
  }
  .nineline {
    float: left;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }
  .leftLine {
    float: left;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent #0099cc transparent transparent;
  }
  .leftxLine {
    float: right;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .rightLine {
    float: right;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent #0099cc;
  }
  .rightxLine {
    float: left;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .ninetbline {
    width: 30px;
    margin: 0 auto;
    .topLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: transparent transparent #0099cc transparent;
    }
    .topxLine {
      height: 16px;
      position: relative;
      left: 6px;
      width: 15px;
      background: #0099cc;
    }
    .bottomLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: #0099cc transparent transparent transparent;
    }
    .bottomxLine {
      height: 16px;
      position: relative;
      left: 6px;
      width: 15px;
      background: #0099cc;
    }
  }
}
</style>
