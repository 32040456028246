<template>
  <div class="font-size-24">
    <v-row>
      <p>
        本项目的实施包括三个主要阶段：问题识别、问题解决和概念验证。应该指出的是，实施创新项目的过程通常包括更多的阶段，但是，本项目的作者将数量限制为现代TRIZ推荐的三个阶段和一个附加阶段：技术方案整理及评价，还包括工程设计以及实验和技术工作。
      </p>
    </v-row>
    <v-row>
      <p>
        每个阶段，问题识别、问题解决和概念验证都包括一组特定的工具，可以有效地实现该阶段的目标。下图展示了实施创新的一般路线图。
      </p>
    </v-row>
    <v-row align="center">
		<div id="road-map" class="technologyRoadmap">
		  <div class="roadmap_title1">问题识别</div>
		  <div
		    class="roadmap_bg roadmap_1"
		    @click="roadmapClick(1)"
		    :style="triz.roadmapList.includes(1) && 'border: 2px solid red;'"
		  >
		    创新标杆
		  </div>
		  <div
		    class="roadmap_bg roadmap_2"
		    @click="roadmapClick(2)"
		    :style="triz.roadmapList.includes(2) && 'border: 2px solid red;'"
		  >
		    功能分析
		  </div>
		  <div
		    class="roadmap_bg roadmap_3"
		    @click="roadmapClick(3)"
		    :style="triz.roadmapList.includes(3) && 'border: 2px solid red;'"
		  >
		    流分析
		  </div>
		  <div
		    class="roadmap_bg roadmap_4"
		    @click="roadmapClick(4)"
		    :style="triz.roadmapList.includes(4) && 'border: 2px solid red;'"
		  >
		    因果链分析
		  </div>
		  <div
		    class="roadmap_bg roadmap_5"
		    @click="roadmapClick(5)"
		    :style="triz.roadmapList.includes(5) && 'border: 2px solid red;'"
		  >
		    进化法则分析
		  </div>
		  <div
		    class="roadmap_bg roadmap_6"
		    @click="roadmapClick(6)"
		    :style="triz.roadmapList.includes(6) && 'border: 2px solid red;'"
		  >
		    裁剪
		  </div>
		  <div
		    class="roadmap_bg roadmap_7"
		    @click="roadmapClick(7)"
		    :style="triz.roadmapList.includes(7) && 'border: 2px solid red;'"
		  >
		    特性传递
		  </div>
		  <div
		    class="roadmap_bg roadmap_8"
		    @click="roadmapClick(8)"
		    :style="triz.roadmapList.includes(8) && 'border: 2px solid red;'"
		  >
		    关键问题分析
		  </div>
		  <div class="roadmap_title2">问题解决</div>
		  <div
		    class="roadmap_bg roadmap_9"
		    @click="roadmapClick(9)"
		    :style="triz.roadmapList.includes(9) && 'border: 2px solid red;'"
		  >
		    发明问题解决算法ARIZ应用
		  </div>
		  <div
		    class="roadmap_bg roadmap_10"
		    @click="roadmapClick(10)"
		    :style="triz.roadmapList.includes(10) && 'border: 2px solid red;'"
		  >
		    克隆问题的应用
		  </div>
		  <div
		    class="roadmap_bg roadmap_11"
		    @click="roadmapClick(11)"
		    :style="triz.roadmapList.includes(11) && 'border: 2px solid red;'"
		  >
		    发明原理的应用
		  </div>
		  <div
		    class="roadmap_bg roadmap_12"
		    @click="roadmapClick(12)"
		    :style="triz.roadmapList.includes(12) && 'border: 2px solid red;'"
		  >
		    概念方向开发
		  </div>
		  <div
		    class="roadmap_bg roadmap_13"
		    @click="roadmapClick(13)"
		    :style="triz.roadmapList.includes(13) && 'border: 2px solid red;'"
		  >
		    功能导向搜索
		  </div>
		  <div
		    class="roadmap_bg roadmap_14"
		    @click="roadmapClick(14)"
		    :style="triz.roadmapList.includes(14) && 'border: 2px solid red;'"
		  >
		    标准解的应用
		  </div>
		  <div
		    class="roadmap_bg roadmap_15"
		    @click="roadmapClick(15)"
		    :style="triz.roadmapList.includes(15) && 'border: 2px solid red;'"
		  >
		    科学效应库的应用
		  </div>
		  <div class="roadmap_title3">概念验证</div>
		  <div
		    class="roadmap_bg roadmap_16"
		    @click="roadmapClick(16)"
		    :style="triz.roadmapList.includes(16) && 'border: 2px solid red;'"
		  >
		    解决次级问题
		  </div>
		  <div
		    class="roadmap_bg roadmap_17"
		    @click="roadmapClick(17)"
		    :style="triz.roadmapList.includes(17) && 'border: 2px solid red;'"
		  >
		    方案验证
		  </div>
		  <div
		    class="roadmap_bg roadmap_18"
		    @click="roadmapClick(18)"
		    :style="triz.roadmapList.includes(18) && 'border: 2px solid red;'"
		  >
		    超效应分析
		  </div>
		  <div
		    class="roadmap_bg roadmap_19"
		    @click="roadmapClick(19)"
		    :style="triz.roadmapList.includes(19) && 'border: 2px solid red;'"
		  >
		    概念评估
		  </div>
		</div>
	</v-row>
    <v-row align="center" justify="center" > 图 技术路线图 <!-- <HelpIcon help="techRoad" /> --></v-row>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import HelpIcon from '@/components/HelpIcon'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Tinymce,
    HelpIcon,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
      // roadmapList:[]
    }
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    roadmapClick(e) {
      if (!this.triz.roadmapList) this.triz.roadmapList = []
      if (this.triz.roadmapList.includes(e)) {
        this.triz.roadmapList.splice(this.triz.roadmapList.indexOf(e), 1)
      } else {
        this.triz.roadmapList.push(e)
      }
      this.change()
      console.log('this.roadmapList', this.triz.roadmapList)
    },
  },
}
</script>
<style scoped lang="scss">
.technologyRoadmap {
  background-image: url(../../../assets/bg.png);
  background-size: 100% 100%;
  width: 980px;
  height: 514px;
  position: relative;
  font-size: 16px;
}
.roadmap_bg {
  background: linear-gradient(#1976d2, #1976d29e);
  width: auto;
  position: absolute;
  box-shadow: 0 0 2px #ddd;
  padding: 5px;
  border: 1px solid #1976d2;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  color: aliceblue;
}
.roadmap_title1 {
  position: absolute;
  top: 10px;
  left: 20px;
}
.roadmap_title2 {
  position: absolute;
  top: 150px;
  left: 20px;
}
.roadmap_title3 {
  position: absolute;
  top: 345px;
  left: 20px;
}
.roadmap_1 {
  top: 48px;
  left: 20px;
}
.roadmap_2 {
  top: 48px;
  left: 118px;
}
.roadmap_3 {
  top: 48px;
  left: 210px;
  min-width: 85px;
}
.roadmap_4 {
  top: 48px;
  left: 318px;
  min-width: 132px;
}
.roadmap_5 {
  top: 48px;
  left: 480px;
}
.roadmap_6 {
  top: 10px;
  left: 640px;
  min-width: 130px;
}
.roadmap_7 {
  top: 88px;
  left: 640px;
  min-width: 130px;
}
.roadmap_8 {
  top: 48px;
  left: 810px;
  min-width: 130px;
}
.roadmap_9 {
  top: 206px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_10 {
  top: 236px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_11 {
  top: 266px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_12 {
  top: 152px;
  left: 390px;
  min-width: 145px;
}
.roadmap_13 {
  top: 206px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_14 {
  top: 236px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_15 {
  top: 266px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_16 {
  top: 352px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_17 {
  top: 390px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_18 {
  top: 428px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_19 {
  top: 466px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
</style>
