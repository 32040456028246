<template>
  <table style="text-align: center;">
    <tr style="height: 50px">
      <th>选择</th>
      <th>编号</th>
      <th>方案简介</th>
      <th>成本优势</th>
      <th>复杂度优势</th>
      <th>可行性</th>
      <th>处理效果</th>
      <th style="width: 80px;">总计</th>
    </tr>
    <tr v-for="(solution, i) in solutionScores.filter(ele => {
          return all?.some(solution => solution.id == ele.id)
        })" :key="i">
      <td  class="Triz_checkbox" style="width:60px;">
          <v-checkbox :value="solution.id" v-model="solution.checked" @change="change"></v-checkbox>
      </td>
      <td style="text-align: center;width:80px;">方案{{ i + 1 }}</td>
      <td style="width: 200px">
        <div
          v-html="
            all.find((ele) => ele.id === solution.id)
              ? all
                  .find((ele) => ele.id === solution.id)
                  .solutionDetail.replace(/<[^>]+>/g, '')
              : ''
          "
        ></div>
      </td>
      <td>
        <div class="Triz_Select-table" >
            <new-triz-select solo v-model="solution.priceScore" :items="scores" label="请打分" @change="change">
            </new-triz-select>
        </div>
      </td>
      <td>
        <div class="Triz_Select-table" >
            <new-triz-select solo v-model="solution.complexScore" :items="scores" label="请打分" @change="change">
            </new-triz-select>
        </div>
      </td>
      <td>
        <div class="Triz_Select-table" >
            <new-triz-select solo v-model="solution.implementScore" :items="scores" label="请打分" @change="change">
            </new-triz-select>
        </div>
      </td>
      <td>
        <div class="Triz_Select-table" >
            <new-triz-select solo v-model="solution.effectScore" :items="scores" label="请打分" @change="change">
            </new-triz-select>
        </div>
      </td>
      <td>
        <div style="color: red;width: 70px;" v-if="!isNaN(average(solution))">
          <strong>{{ average(solution) }}</strong>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    all: {
      type: Array,
      default: () => [],
    },
    solutionScores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      scores: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }
  },
  model: {
    prop: 'solutionScores',
    event: 'change',
  },
  computed: {
  },
  // watch: {
  //   solutionScores: {
  //     handler(value) {
  //       this.$emit('change', value)
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {},
  methods: {
    average(solution) {
      return (solution.priceScore + solution.complexScore + solution.implementScore + solution.effectScore) / 4
    },
    change() {
      this.$emit('change', this.solutionScores)
    },
  },
}
</script>
