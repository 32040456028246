export const TRIZ_STEPS = [{
		id: '1',
		icon: 'roman-numeral-1',
		name: '项目描述',
		children: [{
				id: '1-1',
				name: '项目背景',
				icon: 'numeric-1',
				component: 'Description',
				toplaceholder: '请输入项目概述',
			},
			{
				id: '1-2',
				name: '问题描述',
				icon: 'numeric-2',
				children: [{
						id: '1-2-1',
						name: '初始情况描述',
						icon: 'numeric-1-circle-outline',
						component: 'BackgroudInit',
						toplaceholder: '对于初始系统进行描述，并提出初始系统存在的问题',
					},
					{
						id: '1-2-2',
						name: '现有系统工作原理',
						icon: 'numeric-2-circle-outline',
						component: 'BackgroudCurrent',
						toplaceholder: '可以通过系统工作原理模型图或工程流程图来展示系统工作原理',
					},
					{
						id: '1-2-3',
						name: '系统目标',
						icon: 'numeric-3-circle-outline',
						component: 'BackgroudTarget',
						toplaceholder: '提出改进系统的目标',
					},
					{
						id: '1-2-4',
						name: '商业目标',
						icon: 'numeric-4-circle-outline',
						component: 'BackgroudBusiness',
						toplaceholder: '提出商业模式及目标',
					},
					{
						id: '1-2-5',
						name: '可量化的项目目标',
						icon: 'numeric-5-circle-outline',
						component: 'BackgroudCalculate',
						toplaceholder: '项目中可改进的量化的指标，如速度/数量/成本等，必须要达到什么程度',
					},
					{
						id: '1-2-6',
						name: '项目的限制（约束）',
						icon: 'numeric-6-circle-outline',
						component: 'BackgroudLimit',
						toplaceholder: '系统中不可以改动的地方',
					},
					{
						id: '1-2-7',
						name: '项目的更改（允许）',
						icon: 'numeric-7-circle-outline',
						component: 'BackgroudAllow',
						toplaceholder: '系统中可以改动的地方',
					},
				],
			},
			{
				id: '1-3',
				name: '技术路线',
				icon: 'numeric-3',
				component: 'TechRoad',
			},
		],
	},
	{
		id: '2',
		name: '问题识别',
		icon: 'roman-numeral-2',
		children: [{
				id: '2-1',
				name: '功能分析',
				icon: 'numeric-1',
				children: [{
						id: '2-1-1',
						name: '组件分析',
						icon: 'numeric-1-circle-outline',
						component: 'FunctionalComponent',
					},
					{
						id: '2-1-2',
						name: '相互作用分析',
						icon: 'numeric-2-circle-outline',
						component: 'FunctionalEffect',
					},
					{
						id: '2-1-3',
						name: '功能建模',
						icon: 'numeric-3-circle-outline',
						component: 'FunctionalModel',
					},
					{
						id: '2-1-4',
						name: '功能模型图',
						icon: 'numeric-4-circle-outline',
						component: 'FunctionalModelDiagram',
					},
				],
			},
			{
				id: '2-2',
				name: '因果链分析',
				icon: 'numeric-2',
				component: 'Reason',
				children: [{
						id: '2-2-1',
						name: '因果链关系图',
						icon: 'numeric-1-circle-outline',
						component: 'Reason',
					},
					{
						id: '2-2-2',
						name: '关键问题转化',
						icon: 'numeric-2-circle-outline',
						component: 'Reason_tab',
					}
				],
			},
			{
				id: '2-3',
				name: '功能裁剪',
				icon: 'numeric-3',
				component: 'Clip',
			},
		],
	},
	{
		id: '3',
		name: '问题汇总',
		icon: 'roman-numeral-3',
		children: [{
			id: '3-1',
			name: '分析问题列表（总表）',
			icon: 'numeric-1',
			component: 'ProblemAnalysis',
		}, ],
	},
	{
		id: '4',
		name: '问题解决',
		icon: 'roman-numeral-4',
		children: [{
				id: '4-1',
				name: '解决矛盾的创新原理或技术',
				icon: 'numeric-1',
				component: 'KeyProblem',
			},
			{
				id: '4-2',
				name: '功能导向搜索（FOS）',
				icon: 'numeric-2',
				component: 'GuideSearch',
			},
			{
				id: '4-3',
				name: '标准解',
				icon: 'numeric-3',
				component: 'StandardResovle',
			},
		],
	},
	{
		id: '5',
		name: '技术方案整理与评价',
		icon: 'roman-numeral-5',
		children: [{
				id: '5-1',
				name: '全部技术方案及评价',
				icon: 'numeric-1',
				component: 'Judgement',
			},
			{
				id: '5-2',
				name: '最终确定方案',
				icon: 'numeric-2',
				component: 'Solution',
				toplaceholder: '请输入最终确定方案',
			},
			{
				id: '5-3',
				name: '试验验证',
				icon: 'numeric-3',
				component: 'Experiment',
				toplaceholder: '请输入试验验证内容与结果',
			},
		],
	},
	{
		id: '6',
		name: '成果展示',
		icon: 'roman-numeral-6',
		children: [{
			id: '6-1',
			name: '论文专利',
			icon: 'numeric-1',
			component: 'Result',
			toplaceholder: '请输入成果，如果没有可不填',
		}, ],
	},
]