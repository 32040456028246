<template>
	<v-container class="font-size-24">
		<v-row>
			<ReasonTable :list="flatTreeData(triz.reasonTreeData)" v-model="triz.reasons" @change="reasonChange" />
		</v-row>
		<v-row align="center" justify="center"> 表 因果关系表 </v-row>
		<v-row>
			<p>
				根据前文项目背景提到的，目前传统
				<InnerTextField @input="change" v-model="triz.reasonSlots.slot01" />
				<!-- <v-text-field  placeholder="【请输入】" v-model="triz.reasonSlots.slot01" @input="" max="11" clearable></v-text-field> -->
				系统的主要缺陷，包括
				<InnerTextField @input="change" v-model="triz.reasonSlots.slot02" />
				等，经因果链分析，选择关键缺点因主要有：
				{{ triz.reasons.map(ele => ele.label).join(',') }}
				等。针对这些关键问题，后文将会尝试用TRIZ理论尝试解决。
			</p>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn class="ma-2" outlined color="#1976d2" @click="addClipResolve">
					<v-icon left>mdi-plus</v-icon> 添加解决方案
				</v-btn>
			</v-col>
		</v-row>
		<v-row v-for="(resolve, j) in triz.reasonsResolves"
			:key="`reasons-resolve-desc-${j}`" align="center">
			<div :key="`resolve-${j}`">
				<v-row no-gutters>
					<v-col >
						<strong>基于因果链的解决方案{{ j + 1 }}：</strong>
						<v-btn dense class="ma-2" tile outlined color="red" @click="removeClipResolve(j)">
							<v-icon left>mdi-minus</v-icon> 删除
						</v-btn>
					</v-col>
				</v-row>
				<editor :toref="`reasonsResolves-${j}`" :inputref="`reasonsResolves-${j}Input`" toplaceholder='请输入解决方案'
					v-model="resolve.solutionDetail" @blur="change" />
			</div>
		</v-row>
	</v-container>
</template>

<script>
	import ReasonTree from '@/components/new_triz/ReasonTree.vue'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import ReasonTable from '@/components/new_triz/ReasonTable'
	import editor from './editor/editor.vue'
	export default {
		components: {
			ReasonTree,
			InnerTextField,
			ReasonTable,
			editor
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
			}
		},

		mounted() {},
		methods: {
			removeClipResolve(i) {
				const [{
					id
				}] = this.triz.reasonsResolves.splice(i, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
			addClipResolve() {
				const id = `clip-${new Date().getTime()}`
				if (this.triz.reasonsResolves) {
					this.triz.reasonsResolves.push({
						id,
						solutionDetail: '',
					})
				} else {
					this.triz.reasonsResolves = [{
						id,
						solutionDetail: '',
					}]
				}
				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			change() {
				console.log('change')
				this.$emit('change', this.triz)
			},
			reasonChange(list) {
				console.log('reasonChange')
				this.triz.reasons = list
				this.change()
			},
			flatTreeData(tree) {
				const result = []
				if (!tree.children) return
				this.flatTree(tree.children, result)
				return result.filter((ele) => ele.selected)
			},
			flatTree(children, result) {
				for (let i = 0; i < children.length; i++) {
					const child = children[i]
					result.push(child)
					if (child.children) {
						this.flatTree(child.children, result)
					}
				}
			},
		},
	}
</script>
<style lang="scss">
	.Causal_tree {
		.v-btn:not(.v-btn--round).v-size--default {
			min-width: 10px;
			padding: 18px;
			border-radius: 25px;
		}
	}
</style>