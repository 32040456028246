var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"content",staticStyle:{"width":"1100px","padding":"0"},on:{"mouseover":_vm.onMouseOver,"click":_vm.onMouseClick}},[_c('v-card',{attrs:{"color":"white","elevation":"1"}},[(_vm.triz)?_c('v-form',{attrs:{"readonly":_vm.locked}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"no-gutters":"","cols":"12"}},[_vm._l((_vm.steps),function(main){return [_c('v-row',{key:main.id,staticStyle:{"font-size":"30px","margin-left":"30px","margin-right":"30px","margin-top":"30px"},attrs:{"id":`step-${main.id}`}},[_c('v-col',[_c('SigmentLink',{attrs:{"label":`第${main.id}部分：${main.title}`,"goto":`#step-${main.id}`}})],1)],1),_vm._l((main.children.filter(
                  (ele) =>
                    ![2, 4].includes(main.id) ||
                    (main.id === 2 && _vm.triz.selectedRecognizeSteps.find((step) => step.id === ele.id)) ||
                    (main.id === 4 && _vm.triz.selectedResolveSteps.find((step) => step.id === ele.id))
                )),function(sub,subId){return [_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                    handler: _vm.onIntersect,
                    options: {
                      threshold: [0.5],
                    },
                  }),expression:"{\n                    handler: onIntersect,\n                    options: {\n                      threshold: [0.5],\n                    },\n                  }"}],key:`step-${main.id}-${subId + 1}`,staticStyle:{"font-size":"26px","margin-left":"32px","margin-right":"32px"},attrs:{"id":`step-${main.id}-${sub.id}`}},[_c('v-col',{attrs:{"cols":"5"}},[_c('SigmentLink',{attrs:{"label":`${main.id}.${subId + 1} - ${sub.title}`,"goto":`#step-${main.id}-${subId + 1}`}})],1),_c('v-col',{staticClass:"col_analyzing_tool_btn",attrs:{"cols":"7"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked && main.id === 2),expression:"!locked && main.id === 2"}],attrs:{"tile":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.removeRecognizeStep(sub)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-minus")]),_vm._v("删除此工具 ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked && main.id === 4),expression:"!locked && main.id === 4"}],attrs:{"tile":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.removeResolveStep(sub)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-minus")]),_vm._v("删除此工具 ")],1)],1)],1),_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                    handler: _vm.onIntersect,
                    options: {
                      threshold: [0.5],
                    },
                  }),expression:"{\n                    handler: onIntersect,\n                    options: {\n                      threshold: [0.5],\n                    },\n                  }"}],key:`component-${main.id}-${sub.id}`,staticStyle:{"margin-left":"32px","margin-right":"32px"},attrs:{"id":`component-${main.id}-${sub.id}`}},[_c('v-col',[_c('keep-alive',[_c(sub.component,{tag:"component",staticClass:"mr-8",attrs:{"triz":_vm.triz,"parentid":`${main.id}.${sub.id}`},on:{"change":_vm.change}})],1)],1)],1),_vm._l((sub.children),function(tail,tailId){return [_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                      handler: _vm.onIntersect,
                      options: {
                        threshold: [0.5],
                      },
                    }),expression:"{\n                      handler: onIntersect,\n                      options: {\n                        threshold: [0.5],\n                      },\n                    }"}],key:`step-${main.id}-${subId + 1}-${tailId + 1}`,staticStyle:{"font-size":"26px","margin-left":"36px","margin-right":"36px"},attrs:{"id":`step-${main.id}-${sub.id}-${tail.id}`}},[_c('v-col',{attrs:{"cols":"5"}},[_c('SigmentLink',{attrs:{"label":`${main.id}.${subId + 1}.${tailId + 1} - ${tail.title}`,"goto":`#step-${main.id}-${subId + 1}-${tailId + 1}`}})],1)],1),_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                      handler: _vm.onIntersect,
                      options: {
                        threshold: [0.5],
                      },
                    }),expression:"{\n                      handler: onIntersect,\n                      options: {\n                        threshold: [0.5],\n                      },\n                    }"}],key:`component-${main.id}-${subId + 1}-${tailId + 1}`,staticStyle:{"margin-left":"32px","margin-right":"32px"},attrs:{"id":`component-${main.id}-${sub.id}-${tail.id}`}},[_c('v-col',[_c('keep-alive',[_c(tail.component,{tag:"component",staticClass:"mr-8",attrs:{"parentid":`${main.id}.${sub.id}.${tail.id}`,"triz":_vm.triz},on:{"change":_vm.change}})],1)],1)],1)]})]}),(main.id == 2 && !_vm.locked)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"analyzing_tool_btn"},[(_vm.triz.selectedRecognizeSteps.length < 7)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"dense":"","tile":"","outlined":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加分析工具 ")],1):_vm._e()],1)]}}],null,true)},[_c('v-list',_vm._l((main.children.filter(
                        (ele) => !_vm.triz.selectedRecognizeSteps.some((step) => step.id === ele.id)
                      )),function(method,index){return _c('v-list-item',{key:index,attrs:{"color":"primary"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"dense":"","tile":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.addRecognizeStep(method)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(method.title)+" ")],1)],1)}),1)],1)]:_vm._e(),(main.id == 4 && !_vm.locked)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"analyzing_tool_btn"},[(_vm.triz.selectedResolveSteps.length < 3)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"dense":"","tile":"","outlined":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 添加解决工具 ")],1):_vm._e()],1)]}}],null,true)},[_c('v-list',_vm._l((main.children.filter(
                        (ele) => !_vm.triz.selectedResolveSteps.some((step) => step.id === ele.id)
                      )),function(method,index){return _c('v-list-item',{key:index,attrs:{"color":"primary"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"dense":"","tile":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.addResolveStep(method)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(method.title)+" ")],1)],1)}),1)],1)]:_vm._e()]})],2)],1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"bottom":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('strong',[_vm._v("可添加批注 | ")]),_vm._v(" "+_vm._s(_vm.remarkTarget)+" ")]),_c('v-bottom-sheet',{attrs:{"value":_vm.showRemark,"inset":"","hide-overlay":false,"persistent":_vm.showRemark,"retain-focus":false}},[_c('v-card',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":`${_vm.remarkTarget}的批注`},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showRemark = false}}},[_vm._v("关闭")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addRemark}},[_vm._v("确定")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }