<template>
  <v-container class="font-size-24">
	<p style="text-indent: 0">（3） 系统功能模型</p>
    <v-row justify="center" align="center">
      <FunctionTable :nodes="triz.relationGraphData.nodes" :relations="triz.relationGraphData.links" v-model="triz.functions" @change="change" @relation="relationChange"/>
    </v-row>
    <v-row align="center" justify="center"> 表 功能表 </v-row>
  </v-container>
</template>

<script>
import FunctionTable from '@/components/new_triz/FunctionTable.vue'

export default {
  components: {
    FunctionTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    relationGraphData() {
      let iSno1 = true
      this.triz.relationGraphData.nodes.forEach(e=>{
        if(e.data.super && iSno1){
          e.data.isNo1 = true,
          iSno1 = false
        }else{
          e.data.isNo1 = false
        }
      })
      return this.triz.relationGraphData
    },
  },
  watch: {
    relationGraphData(data) {
      data.nodes.forEach((node) => {
        node.flated = false
      })
    },
  },
  mounted() {
  },
  methods: {
    relationChange() {
   //    console.log('relationChange')
	  // console.log(this.$parent.$parent.$parent)
      // this.$parent.$children.find(ele => ele.$refs.graph).$refs.graph.setJsonData(this.triz.relationGraphData)
    },
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
