<template>
	<v-container class="Standard_solution font-size-24">
		<template>
			<v-row style="width: 1000px;height: 50px;margin-top: -40px;position: absolute;z-index: 9;">
				<v-col cols="8" style="padding: 0px  2px;">
					<v-btn-toggle v-model="btn_toggle" color="primary" variant="outlined">
						<v-btn v-for="(standardResolve, i) in triz.standardResolves" :key="`standardResolves-${i}-btn`">
							<span>标准解{{i+1}}</span>
						</v-btn>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="addStandard()">mdi-plus-circle-outline</v-icon>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="removeStandard(btn_toggle)">mdi-minus-circle-outline</v-icon>
					</v-btn-toggle>
				</v-col>
				<v-col cols="2" style="padding: 0px  2px;" align="right">
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
						@click="addSolution(btn_toggle)">
						添加解决方案
					</v-btn>
				</v-col>
				<v-col cols="2" style="padding: 0px  2px;" align="right">
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px;"
						@click="showVdialog()">
						标准解汇总
					</v-btn>
				</v-col>

			</v-row>
		</template>
		<template v-for="(standardResolve, i) in triz.standardResolves" v-if='i==btn_toggle'>
			<v-row :key="`keyProb-${i}`" align="center">
				<p>
					对于
					<new-triz-select v-model="standardResolve.keyProb" flat :items="[...triz.relationGraphData.links.filter((relation) => !relation.isHide).filter((relation) => relation.lineShape == lineShapes.bad || relation.lineShape == lineShapes.unsatisfy || relation.lineShape == lineShapes.over).map(ele => ({ problem : ele.issue})),...flatTreeData(triz.reasonTreeData),...triz.relationGraphData.links.filter(link => link.rule),].map((item) => item.problem)" solo @change="change" :table="false">
					</new-triz-select>
					，首先识别关键问题：
				</p>

			</v-row>
			<v-row :key="`keyProb-input-${i}`">
				<editor :toref="`recProb-${i}`" :inputref="`recProb-${i}Input`" toplaceholder='请输入' v-model="standardResolve.recProb" @blur="change" />

			</v-row>
			<v-row :key="`problem-${i}`" align="center">
				<p>
					<strong>进而确定有问题的物场模型为</strong>
					<new-triz-select v-model="standardResolve.model1" flat :items="obj_field_level_1" solo @change="change" :table="false">
					</new-triz-select>
					<span class="solution_selectjian">:</span>
					<new-triz-select v-model="standardResolve.model2" flat :items="obj_field_level_2.filter((ele) => ele.parent == standardResolve.model1)" solo @change="change" :table="false">
					</new-triz-select>
				</p>
			</v-row>
			<template v-if="obj_field_level_2.find((ele) => ele.value == standardResolve.model2)?.labels">
				<v-row :key="`keyProb-img-${i}`" align="center">
					<p>
					<p>问题示意图
						<template v-if="obj_field_level_2.some((ele) => ele.value == standardResolve.model2)">
							其中
							<template v-for="(label, k) in obj_field_level_2.find((ele) => ele.value == standardResolve.model2)?.labels">
								，{{ label }}为
								<InnerTextField @input="change" v-model="standardResolve[label]" :key="`${i}-${k}`" />
							</template>
						</template>
					</p>
					</p>
				</v-row>
				<v-row :key="`keyProb-pic-${i}`">
					<v-img :id="`standard-resolve-model2-img-${i}`" :height="standardResolve.model1==1?100:200" :src="`${publicPath}/objfield/model-${standardResolve.model2}.png`" :eager="true" contain></v-img>
				</v-row>
			</template>
			<v-row :key="`keyProb-type-${i}`" align="center">
				<p><strong>则相对应的标准解类型为</strong>
					<new-triz-select v-model="standardResolve.model3" flat :items="obj_field_level_3.filter((ele) => ele.parent == standardResolve.model2).map((ele) => ({ value: ele.main, text: ele.text })) " solo @change="change" :table="false">
					</new-triz-select>
					<span class="solution_selectjian">-</span>
					<new-triz-select v-model="standardResolve.model4" flat :items="obj_field_level_4.filter((ele) => ele.main == standardResolve.model3).map((ele) => ({ value: `${ele.main}.${ele.sub}`, text: ele.text }))" solo @change="change" :table="false">
					</new-triz-select>
					<span class="solution_selectjian">-</span>
					<new-triz-select v-model="standardResolve.model5" flat :items="obj_field_level_5.filter((ele) => `${ele.main}.${ele.sub}` == standardResolve.model4).map((ele) => ({ value: `${ele.main}.${ele.sub}.${ele.tail}`, text: `${ele.main}.${ele.sub}.${ele.tail}`+ele.text,tooltip:ele.tooltip ,case:ele.case}))" solo @change="change" :table="false">
					</new-triz-select>
					<span class="solution_selectjian"  v-if="standardResolve.model5=='1.1.8'||standardResolve.model5=='2.4.1'||standardResolve.model5=='5.1.1'" >-</span>
					<new-triz-select v-if="standardResolve.model5=='1.1.8'||standardResolve.model5=='2.4.1'||standardResolve.model5=='5.1.1'" v-model="standardResolve.model6" flat :items="obj_field_level_6.filter((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5).map((ele) => ({ value: `${ele.main}.${ele.sub}.${ele.tail}.${ele.dep}`, text: `${ele.main}.${ele.sub}.${ele.tail}.${ele.dep}`+ele.text,tooltip:ele.tooltip ,case:ele.case }))" solo @change="change" :table="false">
					</new-triz-select>
				</p>
			</v-row>
			<template v-if="obj_field_level_5.find((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5)?.labels">
				<v-row :key="`keyProb-resolve-pic-${i}`" align="center">
					<p>
						<strong>标准解示意图</strong>
						<template v-if="obj_field_level_5.some((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5)">
							其中
							<template v-for="(label, k) in obj_field_level_5.find((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5)?.labels">
								，{{ label }}为
								<InnerTextField @input="change" v-model="standardResolve[label]" :key="k" />
							</template>
						</template>
					</p>
				</v-row>
				<v-row :key="`keyProb-resolve-img-${i}`">
					<v-img :id="`standard-resolve-model5-img-${i}`" :height="450" :src="`${publicPath}/objfield/${standardResolve.model5}.png`" :eager="true" contain></v-img>
				</v-row>
			</template>
			<v-row :key="`keyProb-resolve-text-${i}`">
				<p><strong>由此我们得出方案：</strong></p>
			</v-row>
			<template v-for="(solution, j) in standardResolve?.solutions">
				<v-row :key="`keyProb-solution-${i}-${j}`">
					<p>
						<strong>基于标准解的方案{{ j + 1 }}：</strong>
					</p>
					<v-btn dense class="ma-2" tile outlined color="error" @click="removeSolution(i, j)" style="border-radius: 25px" :key="`button-${i}-${j}`">
						<v-icon left>mdi-minus</v-icon> 删除
					</v-btn>
				</v-row>
				<editor :toref="`solutionDetail-${i}`" :inputref="`solutionDetail-${i}Input`" toplaceholder='请输入' v-model="solution.solutionDetail" @blur="change" />
			</template>

		</template>


		<v-dialog v-model='vdialog' max-width='1145'>
			<v-card>
				<v-row>
					<p><strong>标准解汇总如下：</strong></p>
				</v-row>
				<v-row align="center" justify="center">
					<StandardTable v-model="triz.standardResolves" @change="change" />
				</v-row>
				<v-row align="center" justify="center"> 图 {{ triz.system }}的标准解汇总 </v-row>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import StandardTable from '@/components/new_triz/StandardTable'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import {
		lineShapes
	} from '@/store/default'

	import {
		obj_field_level_1,
		obj_field_level_2,
		obj_field_level_3,
		obj_field_level_4,
		obj_field_level_5,
		obj_field_level_6,
	} from '@/store/objfield.js'

	import editor from './editor/editor.vue'

	export default {
		components: {
			StandardTable,
			editor,
			InnerTextField,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				vdialog: false,
				btn_toggle: 0,
				// triz: this.triz,
				obj_field_level_1,
				obj_field_level_2,
				obj_field_level_3,
				obj_field_level_4,
				obj_field_level_5,
				obj_field_level_6,
				lineShapes,
				publicPath: process.env.VUE_APP_PUBLIC_PATH
			}
		},
		computed: {},
		mounted() {},
		methods: {
			showVdialog() {
				this.vdialog = true
			},
			change() {
				this.$emit('change', this.triz)
			},
			addStandard() {
				const id = `stardard-${new Date().getTime()}`
				if (!this.triz.standardResolves) this.triz.standardResolves = []
				this.triz.standardResolves.push({
					id,
					keyProb: '',
					recProb: '',
					model1: '',
					model2: '',
					model3: '',
					model4: '',
					model5: '',
					solutions: [],
				})
				this.btn_toggle = this.triz.standardResolves.length - 1
				this.change()
			},
			removeStandard(i) {
				const standardResolve = this.triz.standardResolves.splice(i, 1)
				standardResolve.solutions.forEach((solution) => {
					this.triz.solutionScores.splice(
						this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
						1
					)
				})
				if (i > 0) {
					this.btn_toggle = i - 1
				} else {
					this.btn_toggle = 0
				}
				this.change()
			},
			addSolution(i) {
				const id = `solution-${new Date().getTime()}`
				this.triz.standardResolves[i].solutions.push({
					id,
					selectedPrinciple: '',
					solutionDetail: '',
				})
				this.triz.solutionScores.push({
					id,
				})
				this.change()
			},
			removeSolution(i, j) {
				const [{
					id
				}] = this.triz.standardResolves[i].solutions.splice(j, 1)
				this.triz.solutionScores.splice(
					this.triz.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change()
			},
			flatTreeData(tree) {
				const result = []
				if (!tree) return []
				if (!tree.children) return []
				this.flatTree(tree.children, result)
				return result.filter((ele) => ele.selected)
			},
			flatTree(children, result) {
				for (let i = 0; i < children.length; i++) {
					const child = children[i]
					result.push(child)
					if (child.children) {
						this.flatTree(child.children, result)
					}
				}
			},
		},
	}
</script>
<style lang="scss">
	.Standard_solution {
		p {
			text-indent: 1em;
		}
	}

	.solution_problem {
		width: 800px;
		margin-left: 30px;

		p {
			text-indent: 1em;
		}
	}

	.solution_select {
		position: relative;
		top: 8px;
	}

	.solution_selectjian {
		position: relative;
		top: 6px;
	}

	.WTSolution {
		.tinymceClass {
			margin-left: 30px;
			text-indent: 1em;
		}

		.mce-content-body {
			width: 800px;
		}
	}
</style>