<template>
	<v-container class="font-size-24">
		<p style="text-indent: 0">（1）确定系统、组件、超系统分析。</p>
		<v-row>
			<v-col cols="12" sm="6" md="4">
				<v-textarea rows="2" label="界定问题系统边界" @input="change" v-model="triz.functionSlots.slot01" clearable></v-textarea>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-textarea rows="2" label="明确问题系统功能" @input="change" v-model="triz.functionSlots.slot02" clearable></v-textarea>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-textarea rows="2" label="确定原有存在问题系统的产品名称*" hint="此项为必填项" @input="change" v-model="triz.functionSlots.slot03"
					persistent-hint required clearable></v-textarea>
			</v-col>
		</v-row>
		<p style="text-indent: 0">根据系统目标和约束选择合适的系统边界，完成系统和超系统组件分析：</p>
		<v-row>
			<v-col cols="12" sm="6" md="4">
				<v-card color="#fff" height="320" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
					<div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%;height: 50px;">
						<v-row class="d-flex">
							<v-col cols="9">工程系统</v-col>
						</v-row>
					</div>
					<v-row style="height: 270px;overflow: auto;">
						<v-col style="font-size: 18px">
								{{ triz.functionSlots.slot03 }}
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card color="#fff" min-height="320" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
					<div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%;height: 50px;">
						<v-row class="d-flex">
							<v-col cols="10">系统组件</v-col>
							<v-col cols="2">
								<v-icon color="green"  @click="addSystemComponents">mdi-plus-circle-outline</v-icon>
							</v-col>
						</v-row>
					</div>
				
					<v-list class="list" style="height: 270px;overflow: auto;">
						<v-list-item v-for="(item, i) in triz.relationGraphData.nodes.filter((ele) => ele.data.comp)" :key="i" style="height: 50px;">
							<v-col cols="10">
								<v-text-field  placeholder="【请输入】" v-model="item.text" @input="changeNodeText(item)" max="11" clearable></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-icon color="red" @click="removeItem(item)" v-show="triz.relationGraphData.nodes.filter((ele) => ele.data.comp).length > 1">mdi-minus-circle-outline</v-icon>
							</v-col>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card color="#fff" min-height="320" style="border: 1px solid #ddd; box-shadow: 0 0 6px #ddd">
					<div color="white lighten-1" style="border-bottom: 1px solid #ddd; width: 100%;height: 50px">
						<v-row class="d-flex">
							<v-col cols="10">超系统组件</v-col>
							<v-col cols="2">
								<v-icon color="green" v-show="triz.relationGraphData.nodes.filter((ele) => ele.data.super).length < 8" @click="addSuper">mdi-plus-circle-outline</v-icon>
							</v-col>
						</v-row>
					</div>
				
					<v-list style="height: 270px;overflow: auto;">
						<v-list-item v-for="(item, i) in triz.relationGraphData.nodes.filter((ele) => ele.data.super)" :key="i" style="height: 50px;">
							<v-col cols="10">
								<v-text-field  v-model="item.text"  max="11" clearable :placeholder="i == 0 ? '【请输入目标组件】' : '【请输入】'"></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-icon color="red" @click="removeItem(item)">mdi-minus-circle-outline</v-icon>
							</v-col>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
		</v-row>
		
		<v-row align="center" justify="center"> 图 系统的组件 </v-row>
	</v-container>
</template>

<script>

	export default {
		components: {
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
			
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
			}
		},
		computed: {
			relationGraphData() {
				return this.triz.relationGraphData
			},
		},
		watch: {
			relationGraphData(data) {
				data.nodes.forEach((node) => {
					node.flated = false
				})
			},
		},
		mounted() {
		},
		methods: {
			changeNodeText(node) {
			  // this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.changeNodeText(node)
			},
			addSystemComponents() {
				const nodes = this.triz.relationGraphData.nodes

				const id = `${new Date().getTime()}`
				const text = ''
				const item = {
					id,
					text,
					data: {
						comp: true
					}
				}
				nodes.push(item)
				this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
				this.triz.relations.forEach((ele) => {
					ele.push(0)
				})
				this.change()
			},
			addSuper() {
				const nodes = this.triz.relationGraphData.nodes

				const id = `${new Date().getTime()}`
				const text = ''
				const item = {
					id,
					text,
					data: {
						super: true
					}
				}
				nodes.push(item)
				this.triz.relations.push(new Array(this.triz.relations.length).fill(0))
				this.triz.relations.forEach((ele) => {
					ele.push(0)
				})
				this.change()
			},
			removeItem(item) {
				const index = this.triz.relationGraphData.nodes.findIndex((ele) => ele.id === item.id)
				this.triz.relations.splice(index + 1, 1)
				this.triz.relations.forEach((ele) => {
					ele.splice(index + 1, 1)
				})
				this.triz.relationGraphData.nodes.splice(index, 1)
				this.triz.clips.forEach((clip) => {
					const index = clip.selected.findIndex((select) => select.id === item.id)
					if (index !== -1) {
						clip.selected.splice(index, 1)
					}
				})
				this.change()
			},
			change(data) {
				this.$emit('change', this.triz)
			},
		},
	}
</script>