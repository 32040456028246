<template>
  <v-container style="font-size: 24px" class="Standard_solution">
    <template v-for="(standardResolve, i) in triz.standardResolves">
      <v-row :key="`keyProb-${i}`">
        <p>
          对于
          <triz-select
            v-model="standardResolve.keyProb"
            flat
            :items="
              [
                ...triz.relationGraphData.links
                  .filter((relation) => !relation.isHide)
                  .filter(
                    (relation) => relation.lineShape == lineShapes.bad || relation.lineShape == lineShapes.unsatisfy || relation.lineShape == lineShapes.over
                  ).map(ele => ({ problem : ele.issue})),
                ...flatTreeData(triz.reasonTreeData),
                ...triz.relationGraphData.links.filter(link => link.rule),
              ].map((item) => item.problem)
            "
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
          ，首先识别关键问题：
        </p>
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeStandard(i)">
          <v-icon left>mdi-minus</v-icon> 删除
        </v-btn>
      </v-row>
      <v-row :key="`keyProb-input-${i}`">
        <triz-validator rules="required" id="标准解-识别关键问题">
          <Tinymce class="solution_problem" v-model="standardResolve.recProb" @input="change" placeholder="请输入" />
        </triz-validator>
      </v-row>
      <v-row :key="`problem-${i}`">
        <p><strong>进而确定有问题的物场模型为</strong></p>
        <triz-validator rules="required" id="标准解-有问题的物场模型" class="solution_select">
          <triz-select v-model="standardResolve.model1" flat :items="obj_field_level_1" solo @change="change" :table="false">
          </triz-select>
        </triz-validator>
        <span class="solution_selectjian">:</span>
        <triz-validator rules="required" id="标准解-有问题的物场模型" class="solution_select">
          <triz-select
            v-model="standardResolve.model2"
            flat
            :items="obj_field_level_2.filter((ele) => ele.parent == standardResolve.model1)"
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
        </triz-validator>
      </v-row>
      <template v-if="obj_field_level_2.find((ele) => ele.value == standardResolve.model2)?.labels">
        <v-row :key="`keyProb-img-${i}`">
          <p>
            <strong>问题示意图</strong>
            <template v-if="obj_field_level_2.some((ele) => ele.value == standardResolve.model2)">
              其中
              <template
                v-for="(label, k) in obj_field_level_2.find((ele) => ele.value == standardResolve.model2)?.labels"
              >
                ，{{ label }}为<InnerTextField @input="change" v-model="standardResolve[label]" :key="`${i}-${k}`" />
              </template>
            </template>
          </p>
        </v-row>
        <v-row :key="`keyProb-pic-${i}`" justify="center">
          <img :id="`standard-resolve-model2-img-${i}`" :height="standardResolve.model1==1?100:200" :src="`${publicPath}/objfield/model-${standardResolve.model2}.png`" :eager="true" contain></img>
        </v-row>
      </template>
      <v-row :key="`keyProb-type-${i}`">
        <p><strong>则相对应的标准解类型为</strong></p>
        <triz-validator rules="required" id="标准解-相对应的标准解类型" class="solution_select">
          <triz-select
            v-model="standardResolve.model3"
            flat
            :items="
              obj_field_level_3
                .filter((ele) => ele.parent == standardResolve.model2)
                .map((ele) => ({ value: ele.main, text: ele.text }))
            "
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
        </triz-validator>
        <span class="solution_selectjian">-</span>
        <triz-validator rules="required" id="标准解-相对应的标准解类型" class="solution_select">
          <triz-select
            v-model="standardResolve.model4"
            flat
            :items="
              obj_field_level_4
                .filter((ele) => ele.main == standardResolve.model3)
                .map((ele) => ({ value: `${ele.main}.${ele.sub}`, text: ele.text }))
            "
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
        </triz-validator>
        <span class="solution_selectjian">-</span>
        <triz-validator rules="required" id="标准解-相对应的标准解类型" class="solution_select">
          <triz-select
            v-model="standardResolve.model5"
            flat
            :items="
              obj_field_level_5
                .filter((ele) => `${ele.main}.${ele.sub}` == standardResolve.model4)
                .map((ele) => ({ value: `${ele.main}.${ele.sub}.${ele.tail}`, text: ele.text }))
            "
            solo
            @change="change"
            :table="false"
          >
          </triz-select>
        </triz-validator>
      </v-row>
      <template v-if="obj_field_level_5.find(
                  (ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5
                )?.labels">
        <v-row :key="`keyProb-resolve-pic-${i}`">
          <p>
            <strong>标准解示意图</strong>
            <template
              v-if="obj_field_level_5.some((ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5)"
            >
              其中
              <template
                v-for="(label, k) in obj_field_level_5.find(
                  (ele) => `${ele.main}.${ele.sub}.${ele.tail}` == standardResolve.model5
                )?.labels"
              >
                ，{{ label }}为<InnerTextField @input="change" v-model="standardResolve[label]" :key="k" />
              </template>
            </template>
          </p>
        </v-row>
        <v-row :key="`keyProb-resolve-img-${i}`" justify="center">
          <img :id="`standard-resolve-model5-img-${i}`" :height="450" :src="`${publicPath}/objfield/${standardResolve.model5}.png`" :eager="true" contain></img>
        </v-row>
      </template>
      <v-row :key="`keyProb-resolve-text-${i}`">
        <p><strong>由此我们得出方案：</strong></p>
      </v-row>
      <template v-for="(solution, j) in standardResolve?.solutions">
        <v-row :key="`keyProb-solution-${i}-${j}`">
          <p>
            <strong>基于标准解的方案{{ j + 1 }}：</strong>
          </p>
          <v-btn
            v-show="!locked"
            dense
            class="ma-2"
            tile
            outlined
            color="error"
            @click="removeSolution(i, j)"
            :key="`button-${i}-${j}`"
          >
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <triz-validator rules="required" id="标准解-方案" :key="`rich-${i}-${j}`" class="WTSolution">
          <Tinymce v-model="solution.solutionDetail" @input="change" placeholder="请输入" />
        </triz-validator>
      </template>
      <v-row :key="`resolution-${i}`" style="margin-left: 20px">
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addSolution(i)">
          <v-icon left>mdi-plus</v-icon> 添加解决方案
        </v-btn>
      </v-row>
    </template>
    <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addStandard">
      <v-icon left>mdi-plus</v-icon> 添加标准解
    </v-btn>
    <!-- <v-row class="special_p">
      <p>
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addStandard">
          <v-icon left>mdi-plus</v-icon> 添加标准解
        </v-btn>
      </p>
    </v-row> -->

    <v-row>
      <p><strong>标准解汇总如下：</strong></p>
    </v-row>
    <v-row align="center" justify="center">
      <StandardTable v-model="triz.standardResolves" @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 图 {{ triz.system }}的标准解汇总 </v-row>
  </v-container>
</template>

<script>
import StandardTable from '@/components/StandardTable'
import InnerTextField from '@/components/InnerTextField'
import { lineShapes } from '../../../store/default'

import {
  obj_field_level_1,
  obj_field_level_2,
  obj_field_level_3,
  obj_field_level_4,
  obj_field_level_5,
} from '@/store/objfield.js'
import Tinymce from '@/components/Tinymce'

export default {
  components: {
    StandardTable,
    Tinymce,
    InnerTextField,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
      obj_field_level_1,
      obj_field_level_2,
      obj_field_level_3,
      obj_field_level_4,
      obj_field_level_5,
      lineShapes,
      publicPath: process.env.VUE_APP_PUBLIC_PATH
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    addStandard() {
      const id = `stardard-${new Date().getTime()}`
      if (!this.triz.standardResolves) this.triz.standardResolves = []
      this.triz.standardResolves.push({
        id,
        keyProb: '',
        recProb: '',
        model1: '',
        model2: '',
        model3: '',
        model4: '',
        model5: '',
        solutions: [],
      })
      this.change()
    },
    removeStandard(i) {
      const standardResolve = this.triz.standardResolves.splice(i, 1)
      standardResolve.solutions.forEach((solution) => {
        this.triz.solutionScores.splice(
          this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
          1
        )
      })
      this.change()
    },
    addSolution(i) {
      const id = `solution-${new Date().getTime()}`
      this.triz.standardResolves[i].solutions.push({
        id,
        selectedPrinciple: '',
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeSolution(i, j) {
      const [{ id }] = this.triz.standardResolves[i].solutions.splice(j, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
    flatTreeData(tree) {
      const result = []
      if (!tree) return []
      if (!tree.children) return []
      this.flatTree(tree.children, result)
      return result.filter((ele) => ele.selected)
    },
    flatTree(children, result) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        result.push(child)
        if (child.children) {
          this.flatTree(child.children, result)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.Standard_solution {
  p {
    text-indent: 1em;
  }
}
.solution_problem {
  width: 800px;
  margin-left: 30px;
  p {
    text-indent: 1em;
  }
}
.solution_select {
  position: relative;
  top: 8px;
}
.solution_selectjian {
  position: relative;
  top: 6px;
}
.WTSolution {
  .tinymceClass {
    margin-left: 30px;
    text-indent: 1em;
  }
  .mce-content-body {
    width: 800px;
  }
}
</style>
