<template>
	<div class="m-timeline-area" :style="`width: ${width}px`">
		<div class="m-timeline">
			<div :class="['m-timeline-item', {'last': index === timelineDesc.length - 1}]"
				v-for="(desc, index) in timelineDesc" :key="index">
				<div class="u-tail"></div>
				<div class="u-dot"></div>
				<div class="u-content">{{ desc || '--' }}</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Timeline',
		props: {
			timelineDesc: { // 时间轴内容数组
				type: Array,
				default: () => {
					return []
				}
			},
			width: { // 时间轴区域总宽度
				type: Number,
				default: 360
			}
		}
	}
</script>
<style lang="sass">
	
</style>