<template>
	<v-container class="font-size-24">
		<template>
			<v-row style="width: 1000px;height: 50px;margin-top: -50px;position: absolute;z-index: 999;">
				<v-col cols="12" style="padding: 0px  2px;">
					<v-btn-toggle v-model="btn_toggle" color="primary" variant="outlined">
						<v-btn v-for="(keyProblem, i) in triz.keyProblems" :key="`keyProblem-${i}-btn`">
							<span>问题{{i+1}}</span>
						</v-btn>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="addProblem()">mdi-plus-circle-outline</v-icon>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="removeProblem()">mdi-minus-circle-outline</v-icon>
					</v-btn-toggle>
				</v-col>
			</v-row>
		</template>

		<template v-for="(keyProblem, i) in triz.keyProblems" v-if="i==btn_toggle">
			<v-row class="special_p" :key="`keyProblem-${i}-title`"  style="margin-top: 20px;" align="center">
					<strong>{{ i + 1 }}.关键问题{{ i + 1 }}</strong>
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
						@click="addConflict(triz.keyProblems[btn_toggle], 'technical')">
						使用技术矛盾解决
					</v-btn>
					<v-btn dense class="ma-2" tile outlined color="#1976d2" style="border-radius: 25px"
						@click="addConflict(triz.keyProblems[btn_toggle], 'physical')">
						使用物理矛盾解决
					</v-btn>
			</v-row>
			
			<v-row :key="`keyProblem-${i}-head`"  align="center">
				<p>
					{{ triz.problems }}
					对于问题
					<new-triz-select v-model="keyProblem.description" flat :items="
					  [
						...triz.relationGraphData.links
						  .filter((link) => !link.isHide)
						  .filter(
							(link) =>
							  link.lineShape == lineShapes.bad ||
							  link.lineShape == lineShapes.unsatisfy ||
							  link.lineShape == lineShapes.over
						  )
						  .map((ele) => ({ problem: ele.issue })),
						...flatTreeData(triz.reasonTreeData ? triz.reasonTreeData : {}),
						...triz.relationGraphData.links.filter((link) => link.rule),
					  ].filter((item) => item.problem).map((item) => item.problem)
					" solo @change="change" :table="false">
					</new-triz-select>
					。下面使用矛盾创新原理工具进行分析。
				</p>
			</v-row>
			
			<v-container :key="`keyProblem-${i}-detail`" >
				<template v-for="(conflict, j) in keyProblem.conflicts">
					<template v-if="conflict.type == 'technical'">
						<v-row :key="`keyProblem-${i}-mark-${j}`" align="center">
							<strong>{{ i + 1 }}.{{ j + 1 }}-使用技术矛盾解决问题</strong>
							<v-btn  dense class="ma-2" tile outlined color="error" style="border-radius: 25px"
								@click="removeConflict(keyProblem, j)">
								<v-icon left>mdi-minus</v-icon> 删除
							</v-btn>
						</v-row>
						<Technical :key="`tech-keyProblem-${i}-technical-${j}`" :triz="triz" :conflict="conflict"
							:parentid="``" @change="change"></Technical>
					</template>
					<template v-if="conflict.type == 'physical' ">
						<v-row :key="`keyProblem`" align="center">
							<strong>{{ i + 1 }}.{{ j + 1 }}-使用物理矛盾解决问题</strong>
							<v-btn  dense class="ma-2" tile outlined color="error"  style="border-radius: 25px"
								@click="removeConflict(keyProblem, j)">
								<v-icon left>mdi-minus</v-icon> 删除
							</v-btn>
						</v-row>
						<Physical :key="`keyProblem-${i}-physical-${j}`" :conflicts="conflict.technicalConflicts"
							:triz="triz" :conflict="conflict" @change="change"></Physical>
					</template>
				</template>
			</v-container>

		</template>
	</v-container>
</template>

<script>
	import Physical from './Physical'
	import Technical from './Technical'
	import {
		lineShapes
	} from '../../../store/default'

	export default {
		components: {
			Physical,
			Technical,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
			parentid: {
				type: String,
				default: '',
			}
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				lineShapes,
				btn_toggle: 0
				// triz: this.triz,
			}
		},
		computed: {
		},
		mounted() {
			console.log('triz',this.triz)
		},
		methods: {
			change() {
				this.$emit('change', this.triz)
				this.$nextTick(() => {
					this.$forceUpdate()
				})
			},
			addProblem() {
				if (!this.triz.keyProblems) {
					this.triz.keyProblems = []
				}
				this.triz.keyProblems.push({
					description: '',
					conflicts: [],
				})
				this.btn_toggle=this.triz.keyProblems.length-1
				this.change()
			},
			removeProblem() {
				const index = this.btn_toggle
				const keyProblem = this.triz.keyProblems[index]
				keyProblem.conflicts.forEach((conflict) => {
					conflict.solutions.forEach((solution) => {
						this.triz.solutionScores.splice(
							this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
							1
						)
					})
				})
				this.triz.keyProblems.splice(index, 1)
				if(index>0){
					this.btn_toggle=index-1
				}else{
					this.btn_toggle=0
				}
				this.change()
			},
			addConflict(keyProblem, type) {
				console.log('addConflict')
				if (!keyProblem.conflicts) {
					keyProblem.conflicts = []
				}
				keyProblem.conflicts.push({
					id: 'conflict-' + new Date().getTime(),
					type,
					isPhysical: false,
					physicalConflicts: [],
					technicalConflicts: [{
							c_if: '',
							c_then: '',
							but: '',
							good: [],
							bad: [],
						},
						{
							c_if: '',
							c_then: '',
							but: '',
							good: [],
							bad: [],
						},
					],
					selectedConflict: 0,
					principles: [],
					solutions: [],
					selectedPrinciple: '',
					slots: {
						slot01: '',
						slot02: '',
						slot03: '',
						slot04: '',
						slot05: '',
					},
				})
				keyProblem.conflicts.sort((a, b) => a.type > b.type)
				this.change()
			},
			removeConflict(keyProblem, j) {
				const conflict = keyProblem.conflicts[j]
				conflict.solutions.forEach((solution) => {
					this.triz.solutionScores.splice(
						this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
						1
					)
				})
				keyProblem.conflicts.splice(j, 1)
				this.change()
			},
			flatTreeData(tree) {
				const result = []
				if (!tree.children) return []
				this.flatTree(tree.children, result)
				return result.filter((ele) => ele.selected)
			},
			flatTree(children, result) {
				for (let i = 0; i < children.length; i++) {
					const child = children[i]
					result.push(child)
					if (child.children) {
						this.flatTree(child.children, result)
					}
				}
			},
		},
	}
</script>
<style lang="scss">
	.principle_size2 {
		p {
			text-indent: 0 !important;
		}
	}

	.principle_size {
		margin-top: 0 !important;

		p {
			font-size: 18px !important;
			text-indent: 30px !important;
			margin-bottom: 0px !important;
		}
	}

	.margin_select {
		margin: 0 10px;
	}

	// .delete_right{
	//   display: flex;
	//   position: relative;
	//   width: 100%;
	//   .v-btn{
	//     position: absolute;
	//     right: 0;
	//     margin-top: 0px!important;
	//   }
	// }
	.Triz_Select_fujia {
		line-height: 46px !important;

		.v-select__selection {
			text-indent: 10px;
		}
	}
</style>