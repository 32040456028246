export const prompt = [
  { value: '1-1', text: '项目背景' ,prompt:['项目背景，主要用做描述创新项目的由来，大的背景环境，简要概述现有背景环境下，存在的痛点问题，要解决一个什么样的创新，以期达到什么效果']},
  { value: '1-2-1', text: '初始情况描述' ,prompt:['初始情况描述：主要介绍现有技术系统中出现的问题，存在的困难。基本属于宏观问题，不要在这定义问题的原因，不要主观认为问题在哪。因还没有进入到问题分析工具中。不能犯了“经典TRIZ理论”，没有解决正确额问题的错误。']},
  { value: '1-2-2', text: '现有系统工作原理' ,prompt:['现有系统工作原理：一般建议画出工作原理图，能够清晰的解释现有系统的工作原理，也可以用文字进行详细的描述']},
  { value: '1-2-3', text: '系统目标' ,prompt:['系统目标：我们改进系统的主要目标，要解决一个什么问题，宏观上的目标描述']},
  { value: '1-2-4', text: '商业目标' ,prompt:['商业目标：改进系统后我们要完成的商业目标是什么？例如降低成不提升利润的空间']},
  { value: '1-2-5', text: '可量化的项目目标',prompt:['可量化的项目目标：用TRIZ理论工具解决的重要的指标，一定要找到参数，要提升或改进什么参数，参数如果找不到，那么系统改进的目标就不够具体。例如：让PM2.5的颗粒通过率<0.1%' ]},
  { value: '1-2-6', text: '项目的限制（约束）',prompt:['项目的限制（约束）：项目不能改变的地方，要在创新之前约定好，哪些是不能改动的地方，例如工作的基本原理不能改变等等' ]},
  { value: '1-2-7', text: '项目的更改（允许）' ,prompt:['项目的更改（允许）：项目创新过程中可以改动的地方，哪些是可以改进的。例如：材料可以改进等等']},
  { value: '1-3', text: '技术路线' ,prompt:['工具可直接用鼠标选择。']},
  { value: '2-1-1', text: '组件分析',prompt:['组件分析:现有系统的组件分析，要注意组件数量尽量在10个以内，太多的组件会造成思路不清晰。组件的选择需要在同一层次上。超系统组件第一位，应为目标组件']},
  { value: '2-1-2', text: '相互作用分析' ,prompt:[]},
  { value: '2-1-3', text: '功能建模',prompt:['功能建模:TRIZ理论中，一个组件有保持或者改变另一个组件参数的能力就有相互作用。这个参数是一定要找到的，如果没有保持或者改变参数就没有账户作用。' ]},
  { value: '2-1-4', text: '功能模型图' ,prompt:['功能模型图:最直观的展现出组件之间相互作用的图形。本系统根据功能建模自动生成。组件位置可以拖拽，双击模型图中的“功能”，可将直线改为直角折线。请拖拽，布局让创新者能够更加清晰的看清楚系统组件之间的功能问题']},
  { value: '2-2-1', text: '因果链关系图' ,prompt:['因果链分析:0号问题，也是初始问题，他是你要改进系统目标的反向。例如我们要做一个牙刷系统的创新，目标是提升牙刷的清洁效率。那么0号初始问题，就应该是目标的反向，即:牙刷的清洁效率不高。要注意一层问题是“直接”产生上一层问题的原因。这里指的是直接原因，不要跳跃，因为跳跃可能会使你失去了解决问题的一个方法。','AND是只有所有问题都具备才会产生上一层的结果。例如燃烧的下一层问题应该是，可燃物，氧气，温度。这三个是AND关系。而OR的意思下层问题，都可能单独产生上一层的问题','关键缺点的选择，遇到AND向下一层找，遇到OR向上一层找，这样会简化创新的复杂度。因果链分析过程要检查，功能分析过程中的问题，是否涵盖，如果不能涵盖，要进行对比检查。']},
  { value: '2-2-2', text: '关键问题转化',prompt:['因果链分析: 0号问题，也是初始问题，他是你要改进系统目标的反向。例如我们要做一个牙刷系统的创新，目标是提升牙刷的清洁效率。那么0号初始问题，就应该是目标的反向，即:牙刷的清洁效率不高。 要注意一层问题是“直接”产生上一层问题的原因。这里指的是直接原因，不要跳跃，因为跳跃可能会使你失去了解决问题的一个方法。AND是只有所有问题都具备才会产生上一层的结果。例如燃烧的下一层问题应该是，可燃物，氧气，温度。这三个是AND关系。而OR的意思下层问题，都可能单独产生上一层的问题关键缺点的选择，遇到AND向下一层找，遇到OR向上一层找，这样会简化创新的复杂度。 因果链分析过程要检查，功能分析过程中的问题，是否涵盖，如果不能涵盖，要进行对比检查。' ]},
  { value: '2-3', text: '功能裁剪',prompt:['裁剪:裁剪是根据功能分析的功能模型图来做的，如没有做功能分析，请返回功能分析工具模块裁剪过程中如果有用功能不能分配出去那么不能裁剪，如果能裁剪掉，那么大概率是你的功能建模做的不对。裁剪规则三种ABC，一定要选择三种规则。其中','A没有功能对象，那么就没有功能载体。','B:功能对象自己可以完成对自己的功能，那么不需要功能载体。','C:讲所有有用功能转移分配到其他功能载体（组件）身上，即可裁剪，也是我们常用的。' ]},
  { value: '3-1', text: '分析问题列表（总表）' ,prompt:['方案汇总:系统会自动将您生成的方案进行汇总，汇总后的表格应该找到该领域的专家，工作人员进行打分，综合评分最高的，或多种方案组合成最后的解决方案']},
  { value: '4-1', text: '解决矛盾的创新原理或技术' ,prompt:['技术矛盾:通过矛盾，转化为39个工程参数，再根据阿奇舒勒矛盾矩阵，改善一个参数，同时恶化的另一个参数，找到40个发明原理，解决创新问题。要注意的是技术矛盾是两个参数，改善一个恶化另一个。物理矛盾:同一个参数的矛盾，他是在不同场景下的同一个参数的，两个合情合理的需求。例如，手机屏幕要大，因为要看的清楚手机屏幕要小，因为方便携带手机屏幕既要大，又要小，是手机屏幕尺寸这一个参数，合情合理的矛盾。叫做物理矛盾物理矛盾的解决主要方法（这地方给一张图）发明原理的查询，跟技术矛盾不同，他需要通过选择解决物理矛盾方法来查询的，分离矛盾是通过加入导向关键词，来查询的。']},
  { value: '4-2', text: '功能导向搜索（FOS）',prompt:['功能导向搜索:功能导向搜索的本质，是借鉴先进的其他领域成熟做法。故功能的一般化是关键，只有一般化到最根源的情况下，才能够找到不同领域的成熟做法。例如，牙刷主要功能，如果你一般化进程不够，将“去除牙垢“作为一般化功能，那么在世界范围内搜索“去除牙垢”，只会在口腔领域中，并没有跳出局限，也借鉴不了其他领域先进成熟做法。正确的一般化功能应为“去除细小颗粒”，将去除细小颗粒在世界范围内搜索，就可以跳出口腔的限制，在其他领域中借鉴成熟先进方法。建议大家借鉴的领域:太空、军工、医疗、自然界、先进工业、玩具 这几个重要领域的方法' ]},
  { value: '4-3', text: '标准解' ,prompt:[]},
  { value: '5-1', text: '全部技术方案及评价',prompt:[]},
  { value: '5-2', text: '最终确定方案	',prompt:[ '最终确定的方案，可以是单一的解决方案，也可以是多个解决方案的综合']},
  { value: '5-3', text: '试验验证',prompt:[ '可以将实验结果、设计图纸、模拟图放在此处']},
  { value: '6-1', text: '成果展示',prompt:['成果展示:将成品的设计图，或者原理图进行展示' ]},
]
