<template>
  <v-container class="font-size-24">
    <v-row>
      <p>
        在实现系统功能时，资源在绝大多数情况下都是必需的。在创造发明中对资源问题做完整的、充分的思考是至关重要的。创新发明过程中，资源的利用主要涉及以下几个方面内容：
      </p>
    </v-row>
    <v-row>
      <p>（1）可能有哪些资源？</p>
    </v-row>
    <v-row>
      <p>（2）可以方便地获得哪些资源？</p>
    </v-row>
    <v-row>
      <p>（3）准备使用哪些资源？</p>
    </v-row>
    <v-row>
      <p>（4）如何使用资源？</p>
    </v-row>
    <v-row>
      <p>选择资源的顺序，如下表所示</p>
    </v-row>
    <v-row align="center" justify="center">
      <SelectOrderTable />
    </v-row>
    <v-row align="center" justify="center"> 表 选择资源的顺序 </v-row>
    <v-row align="center" justify="center">
      <ResourceUsed v-model="triz.resources" @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 表 传统{{ triz.system }}已用资源分析 </v-row>
    <v-row align="center" justify="center">
      <ResourceTable v-model="triz.resourcesAnalyze" @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 表 资源总分析 </v-row>
  </v-container>
</template>

<script>
import SelectOrderTable from '@/components/SelectOrderTable'
import ResourceUsed from '@/components/ResourceUsed'
import ResourceTable from '@/components/ResourceTable'

export default {
  components: {
    SelectOrderTable,
    ResourceUsed,
    ResourceTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },

  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
